import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchCatalogItemsByCategoryId,
  saveCatalogsListBasedOnCategories,
  setCatalogProductFilter,
} from 'src/redux/actions';
import CategoryProductGridView from './GridView';
import CategoryProductHorizontalView from './HorizontalView';
import { useRouter } from 'next/router';
import CategoryCouponHorizontalView from '../CouponDisplayWidget/CategoryCouponHorizontalView';
import { CATEGORY_TYPE } from '../../../utils/constants';
import { useItemClick } from 'src/hooks/useItemClick';
import { useSSRSelector } from '@/redux/ssrStore';
import { getCachedCategoryItems, cacheCategoryItems, clearCachedItems } from './utils';
import { scrollIfItemPreviouslyClicked } from '../MiddleBody/MiddleBody7/utils';

const CategoryProducsDisplayWidget = (props) => {
  const router = useRouter();
  const catId = router?.query?.cid || props?.config?.category_id;

  const {
    storeData: { store_id },
    categories,
    productTags,
  } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    categories: state.catalogReducer.categories,
    productTags: state.catalogReducer.productTags,
  }));

  const dispatch = useDispatch();
  const [activeCategoryData, setActiveCategoryData] = useState(getActiveCategoryData());
  const [categoryItems, setCategoryItems] = useState([]);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [categoryTagType, setCategoryTagType] = useState(0);
  const [currentSelectedFilter, setCurrentSelectedFilter] = useState({
    is_hide_out_of_stock: false,
    sort_option: {},
  });
  const [onItemClick, getLink] = useItemClick();
  const {
    catalogData: { catalog_items, productFilters },
  } = useSelector((state) => ({
    catalogData: state.catalogReducer,
  }));

  useEffect(() => {
    scrollIfItemPreviouslyClicked();
  }, []);

  /**
   * This useEffect will fetch all the items of active category
   */
  useEffect(() => {
    setCurrentCategoryId(catId);
    if (
      [...productTags, ...categories]?.length
      // && catId != activeCategoryData?.id
    ) {
      // resetting product filters on fetching new category items
      dispatch(
        setCatalogProductFilter({
          is_hide_out_of_stock: false,
          sort_option: {},
        })
      );
      const categoryType =
        productTags?.length && productTags.findIndex((tag) => tag.id === +catId) >= 0
          ? CATEGORY_TYPE.TAG
          : CATEGORY_TYPE.CATEGORY;

      const cachedData = getCachedCategoryItems();
      if (store_id) {
        if (!cachedData.allowReset && catId === cachedData?.currentCategoryId) {
          setCategoryItems(cachedData?.categoryItems || []);
          cacheCategoryItems({ allowReset: true });
          dispatch(
            saveCatalogsListBasedOnCategories({
              category_id: catId,
              items: cachedData?.categoryItems || [],
              isNextPage: false,
              isPaginated: false,
            })
          );
        } else {
          clearCachedItems();
          getProducts(catId, categoryType);
        }
      }
      setCategoryTagType(categoryType);
      if (catId) {
        const data = getActiveCategoryData();
        setActiveCategoryData(data);
      }
    }
  }, [categories, productTags, catId, store_id]);

  useEffect(() => {
    // setting the current category items in view
    if (Object.keys(catalog_items).length && catalog_items[currentCategoryId]) {
      setCategoryItems(catalog_items[currentCategoryId]);
    }
  }, [catalog_items]);

  useEffect(() => {
    // this useeffect will be used for applying filter and sorting options on the categoryItems array
    if (
      categoryItems?.length &&
      Object.keys(productFilters).length &&
      (productFilters?.is_hide_out_of_stock !==
        currentSelectedFilter.is_hide_out_of_stock ||
        productFilters?.sort_option?.id !== currentSelectedFilter?.sort_option?.id)
    ) {
      setCurrentSelectedFilter(productFilters);
      setCategoryItems(filterCurrentCategoryItems(productFilters));
    }
  }, [productFilters, categoryItems]);

  function getActiveCategoryData() {
    const allCategories = [...(productTags || []), ...(categories || [])];
    const activeCatIndex = allCategories.findIndex((el) => el.id === Number(catId));
    return allCategories[activeCatIndex];
  }

  /**
   * This function will filter out items or apply sorting on items and return the new array of items
   * @param {*} filterOptions
   * @return {Array}
   */
  function filterCurrentCategoryItems(filterOptions) {
    if (catalog_items[currentCategoryId]) {
      const currentCategoryItems = JSON.parse(
        JSON.stringify(catalog_items[currentCategoryId])
      );
      let filteredCategoryItems = [];
      let sortKey = '';
      if (filterOptions?.is_hide_out_of_stock) {
        // removing out of stock items
        filteredCategoryItems = currentCategoryItems.filter((item) => item.available);
      } else {
        filteredCategoryItems = [...currentCategoryItems];
      }
      if (filterOptions?.sort_option?.sortType) {
        sortKey =
          filterOptions?.sort_option?.key === 'alphabet' ? 'name' : 'discounted_price';
        filteredCategoryItems = sortCategoryItems(
          filteredCategoryItems,
          sortKey,
          filterOptions?.sort_option?.sortType
        );
      }
      return filteredCategoryItems;
    } else {
      return [];
    }
  }

  /**
   * This function will apply the sorting on the array items. For sorting alphabatically we use localCompare.
   * @param {*} arrayToBeSorted : the array on which sorting will be applied
   * @param {*} sortingKey : key on which sorting will be applied i.e name or price
   * @param {*} sortingType : it will be ascending or descending
   * @return {Array}
   */
  function sortCategoryItems(arrayToBeSorted, sortingKey, sortingType) {
    arrayToBeSorted.sort(function (a, b) {
      let key1;
      let key2;
      if (sortingKey === 'name') {
        key1 = a[sortingKey].toLowerCase();
        key2 = b[sortingKey].toLowerCase();
      } else {
        key1 = a[sortingKey];
        key2 = b[sortingKey];
      }
      if (sortingType === 'ascending') {
        if (key1 < key2)
          // sort ascending
          return -1;
        if (key1 > key2) return 1;
        return 0; // default return value (no sorting)
      } else {
        if (key1 > key2)
          // sort descending
          return -1;
        if (key1 < key2) return 1;
        return 0; // default return value (no sorting)
      }
    });
    return arrayToBeSorted;
  }

  const getProducts = (id, type) => {
    if (!store_id) return;
    return new Promise((resolve, reject) => {
      dispatch(
        fetchCatalogItemsByCategoryId(
          {
            store_id: store_id,
            category_id: id,
            category_type: type,
          },
          (status) => {
            if (status) {
              resolve(status);
            } else {
              reject(status);
            }
          }
        )
      );
    });
  };

  const handleImageClick = (data) => {
    cacheCategoryItems({
      allowReset: false,
      categoryItems,
      currentCategoryId,
    });
    onItemClick(data);
  };

  const renderProductDisplayWidget = (widgetName) => {
    switch (widgetName) {
      case 'CategoryWidgetGridView1':
        return (
          <>
            <CategoryCouponHorizontalView></CategoryCouponHorizontalView>
            <CategoryProductGridView
              onItemsImageClick={handleImageClick}
              getLink={getLink}
              categoryItems={categoryItems}
              activeCategoryData={activeCategoryData}
              config={props.config}
            ></CategoryProductGridView>
          </>
        );
      case 'CategoryWidgetHorizontalView1':
        return (
          <>
            <CategoryCouponHorizontalView></CategoryCouponHorizontalView>
            <CategoryProductHorizontalView
              onItemsImageClick={handleImageClick}
              getLink={getLink}
              categoryItems={categoryItems}
              activeCategoryData={activeCategoryData}
              config={props.config}
              categoryTagType={categoryTagType}
            ></CategoryProductHorizontalView>
          </>
        );
      default:
        return <></>;
    }
  };

  return renderProductDisplayWidget(props.widgetName);
};

export default CategoryProducsDisplayWidget;
